// import { ZoomRoles } from 'src/types'
// import HandUp from '../../assets/hand-up-icon.svg'
// import HandDown from '../../assets/hand-down-icon.svg'

// export function UsersToolbar({
//     usersInSession,
//     onAttach,
//     onDetach,
//     onCloseTab
// }) {
//     return (
//         <ul
//             style={{
//                 position: 'absolute',
//                 right: 0,
//                 bottom: 0,
//                 backgroundColor: '#fff'
//             }}
//         >
//             <button onClick={onCloseTab}>close</button>
//             {usersInSession.map((el) => {
//                 return (
//                     <li key={el.userId + ''}>
//                         <p>{el.displayName}</p>

//                         <div
//                             style={{
//                                 backgroundColor: 'lightgray',
//                                 padding: 4,
//                                 borderRadius: '50%',
//                                 display: 'inline-flex'
//                             }}
//                         >
//                             {el.isHandUp && (
//                                 <img style={{ aspectRatio: 1 }} src={HandUp} />
//                             )}
//                         </div>
//                         <button>{el.muted ? 'muted' : 'unmuted'}</button>
//                         <button
//                             onClick={() => {
//                                 usersInSession.forEach((el) => {
//                                     onDetach(el.userId)
//                                     // handleDetachVideo(el.userId)
//                                 })
//                                 onAttach(el.userId)

//                                 // usersInSession.forEach(el => el.isActiveUser = false)
//                                 // set all users as inactive
//                                 // set current user as active
//                                 // attach video
//                             }}
//                         >
//                             {'attach'}
//                         </button>
//                         <button
//                             onClick={() => {
//                                 // console.log('el', el)
//                                 // detach all video
//                                 // attach amsaan pro user
//                                 onDetach(el.userId)
//                                 const amsaanPro = usersInSession.find(
//                                     (user) => user.role === ZoomRoles.AMSAAN_PRO
//                                 )
//                                 onAttach(amsaanPro.userId)
//                             }}
//                         >
//                             {'detach'}
//                         </button>
//                         <button>
//                             {el.isActiveUser
//                                 ? 'isActiveUser'
//                                 : 'isUnactiveUser'}
//                         </button>
//                         <button>end call</button>
//                     </li>
//                 )
//             })}
//         </ul>
//     )
// }

import React, { useContext } from 'react'
import { ZoomRoles } from 'src/types'
import HandUp from '../../assets/hand-up-icon.svg'
import HandDown from '../../assets/hand-down-icon.svg'
import Cross from '../../assets/corsIcon.svg'
import Decline from '../../assets/cancel-call-icon.svg'
import VolumeOff from '../../assets/volume-off.svg'
import VolumeOn from '../../assets/volume-on.svg'
import zoomContext from 'src/context/zoom-context'

export function UsersToolbar({
    usersInSession,
    onAttach,
    onDetach,
    onCloseTab,
    removeUser,
    toggleAudio
}) {
    const zmClient = useContext(zoomContext)

    return (
        <div style={styles.container}>
            <button onClick={onCloseTab} style={styles.closeButton}>
                <img src={Cross} />
            </button>
            <ul style={styles.userList}>
                {usersInSession
                    .filter((el) => el.role !== ZoomRoles.OPERATOR)
                    .map((el) => (
                        <li
                            key={el.userId}
                            style={{
                                ...styles.userItem,
                                borderColor: el.isActiveUser
                                    ? 'rgb(0, 170, 246)'
                                    : 'transparent'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 12,
                                    marginBottom: 12
                                }}
                            >
                                <p style={styles.userName}>{el.displayName}</p>
                                {el.isHandUp ? (
                                    <div style={styles.handIconContainer}>
                                        <img
                                            style={styles.handIcon}
                                            src={HandUp}
                                            alt="Hand Up"
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <div style={{ display: 'flex', gap: 6 }}>
                                {/* } <button
                                    onClick={() => {
                                        console.log(el.userId)
                                        toggleAudio(el.userId)
                                    }}
                                    style={styles.muteButton}
                                >
                                    <img
                                        src={zmClient
                                            .getMediaStream()
                                            .isAudioMuted(el.userId) ? VolumeOff: VolumeOn}
                                        alt=""
                                    />
                                    {zmClient
                                        .getMediaStream()
                                        .isAudioMuted(el.userId)
                                        ? 'Muted'
                                        : 'Unmuted'}
                                </button> */}
                                {!el.isActiveUser ? (
                                    <button
                                        style={styles.actionButton}
                                        onClick={() => {
                                            usersInSession.forEach((user) =>
                                                onDetach(user.userId)
                                            )
                                            onAttach(el.userId)
                                        }}
                                    >
                                        <img
                                            style={{ rotate: '45deg' }}
                                            src={Cross}
                                            alt=""
                                        />
                                    </button>
                                ) : (
                                    <button
                                        disabled={
                                            el.role === ZoomRoles.AMSAAN_PRO
                                        }
                                        style={styles.actionButton}
                                        onClick={() => {
                                            onDetach(el.userId)
                                            const amsaanPro =
                                                usersInSession.find(
                                                    (user) =>
                                                        user.role ===
                                                        ZoomRoles.AMSAAN_PRO
                                                )
                                            onAttach(amsaanPro?.userId)
                                        }}
                                    >
                                        <img src={Cross} alt="" />
                                    </button>
                                )}

                                <button
                                    onClick={() => removeUser(el.userId)}
                                    style={styles.endCallButton}
                                >
                                    <img src={Decline} alt="" />
                                </button>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    )
}

const styles = {
    container: {
        position: 'absolute',
        right: 20,
        bottom: 20,
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        padding: 16,
        width: 300,
        zIndex: 1000,
        overflow: 'auto',
        maxHeight: '80vh'
    },
    closeButton: {
        display: 'block',
        backgroundColor: '#f44336',
        color: '#fff',
        border: 'none',
        borderRadius: 4,
        padding: '8px 12px',
        cursor: 'pointer',
        marginBottom: 10,
        marginLeft: 'auto'
        // width: '100%'
    },
    userList: {
        listStyleType: 'none',
        padding: 0,
        margin: 0
    },
    userItem: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        padding: 12,
        marginBottom: 8,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
        transition: 'border-color 0.3s ease',
        border: '1px solid transparent'
    },
    userName: {
        margin: 0,
        fontSize: 16,
        fontWeight: 'bold',
        // marginBottom: 8,
        textAlign: 'center'
    },
    handIconContainer: {
        backgroundColor: '#e0e0e0',
        padding: 8,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        // marginBottom: 8
    },
    handIcon: {
        width: 24,
        height: 24
    },
    muteButton: {
        backgroundColor: '#2196f3',
        color: '#fff',
        border: 'none',
        borderRadius: 4,
        padding: '6px 12px',
        cursor: 'pointer'
        // marginBottom: 8
    },
    actionButton: {
        backgroundColor: '#4caf50',
        color: '#fff',
        border: 'none',
        borderRadius: 4,
        padding: '6px 12px',
        cursor: 'pointer'
        // marginBottom: 8
    },
    activeButton: {
        backgroundColor: '#ff9800',
        color: '#fff',
        border: 'none',
        borderRadius: 4,
        padding: '6px 12px',
        cursor: 'pointer',
        marginBottom: 8
    },
    endCallButton: {
        backgroundColor: '#f44336',
        color: '#fff',
        border: 'none',
        borderRadius: 4,
        padding: '6px 12px',
        cursor: 'pointer'
    }
}
