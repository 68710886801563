export const startVideo = async (stream, client) => {
    if (
        (!stream.isSupportMultipleVideos() &&
            typeof OffscreenCanvas === 'function') ||
        /android/i.test(navigator.userAgent)
    ) {
        // start video - video will render automatically on HTML Video Element if MediaStreamTrackProcessor is supported
        stream
            .startVideo({
                videoElement: document.querySelector('#self-view-video'),
                originalRatio: true
            })
            .then(() => {
                // if MediaStreamTrackProcessor is not supported
                if (!(typeof MediaStreamTrackProcessor === 'function')) {
                    // render video on HTML Canvas Element
                    stream
                        .renderVideo(
                            document.querySelector('#self-view-canvas'),
                            client.getCurrentUserInfo().userId,
                            254,
                            143,
                            0,
                            0,
                            2
                        )
                        .then(() => {
                            // show HTML Canvas Element in DOM
                            console.log(
                                'Operators video rendered successfully in Canvas Element in DOM'
                            )
                        })
                        .catch((error) => {
                            console.log('Error in render video ', error)
                            client.leave()
                        })
                } else {
                    // show HTML Video Element in DOM
                    console.log(
                        'Operators video rendered successfully in Video Element in DOM'
                    )
                }
            })
            .catch((error) => {
                console.log('Error in render video ', error)
                client.leave()
            })
        // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
    } else {
        // start video
        stream
            .startVideo({
                originalRatio: true,
                videoElement: document.querySelector('#self-view-video'),
            })
            .then(() => {
                // render video on HTML Canvas Element
                stream
                    .renderVideo(
                        document.querySelector('#self-view-video'),
                        client.getCurrentUserInfo().userId,
                        254,
                        143,
                        0,
                        0,
                        2
                    )
                    .then(() => {
                        // show HTML Canvas Element in DOM
                        console.log(
                            'Operators video rendered successfully in Canvas Element with SharedBufferArray'
                        )
                    })
                    .catch((error) => {
                        console.log('Error in render video ', error)
                        client.leave()
                    })
            })
            .catch((error) => {
                console.log('Error in start video ', error)
            })
    }
}
