import React, { useEffect, useRef, useState } from 'react'
import Lottie from 'react-lottie'
import { Box } from '@material-ui/core'
import { Warning } from '@material-ui/icons'

import { useCallContext } from '../../reducers/Call/CallProvider'
import Logo from '../../Logo'
import Loader from '../../assets/lottie/loader.json'
import { fullHeight } from '../../utils/constants'
import { ZoomCall } from '../../components/ZoomCall'
import { CALL_TYPE } from '../../constants/defaultConstants'
import { CountdownTimer } from 'src/components/CountdownTimer'
//сторінка дзвінка
import { CallPreview } from './CallPreview'
//вихідний дзвінок
import { OutGoingCallPreview } from './OutgoingCallPreview'
import { CallType } from 'src/types'
import { ZoomGroupCall } from 'src/components/ZoomGroupCall'

export const CallPage = () => {
    const sinceStart = useRef(null)
    const [secondsSinceStart, setSecondsSinceStart] = useState(0)

    const { callState, streamAvailable, handleAccept, isInStream } =
        useCallContext()
    const { selectedCall, operatorToken } = callState
    const { sessionName, startedAt } = selectedCall
    const isIncomingCall =
        selectedCall.type === CALL_TYPE.INCOMING_CALL_AMSAAN ||
        selectedCall.type === CALL_TYPE.INCOMING_CALL_AMSAANPRO ||
        selectedCall.type === CallType.widgetToOperator ||
        selectedCall.type === CallType.laptopToGroupCall;
    const isGroupCall = selectedCall.type === CallType.laptopToGroupCall;
    
    const lottieOptions = {
        animationData: Loader,
        loop: true,
        autoplay: true
    }

    useEffect(() => {
        if (!streamAvailable) return
        sinceStart.current = Date.now()

        const interval = setInterval(() => {
            const currentTime = Date.now()
            const elapsedTime = Math.floor(
                (currentTime - sinceStart.current) / 1000
            )
            setSecondsSinceStart(elapsedTime)
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [streamAvailable])

    const SECONDS = 60
    const MINUTES = 3

    const initialBalance = Math.ceil(
        callState?.selectedCall?.user?.balance?.balance
    )
    const currentBalance = Math.ceil(initialBalance - secondsSinceStart)
    const isBalanceEnds = currentBalance <= MINUTES * SECONDS

    const isAmsaanPro = Boolean(callState?.selectedCall?.user?.employee?.id)
    const isForceDisconnectEnable =
        !isAmsaanPro && Math.ceil(currentBalance) <= 0

// console.log('operatorToken', operatorToken)
    return (
        <Box width="100%" style={styles.wrapper}>
            {!isAmsaanPro &&
            currentBalance &&
            isBalanceEnds &&
            streamAvailable ? (
                <div style={styles.countdownWrapper}>
                    <Warning />
                    <CountdownTimer countdownTime={currentBalance} />
                </div>
            ) : null}
            {!!selectedCall.sessionName.length &&
                !isInStream &&
                isIncomingCall && (
                    <CallPreview
                        startedAt={new Date(startedAt)}
                        handleAccept={handleAccept}
                    />
                )}

            {!!selectedCall.sessionName.length &&
                !isInStream &&
                selectedCall.type === CALL_TYPE.OUTGOING_CALL && (
                    <OutGoingCallPreview
                        startedAt={new Date()}
                        handleDecline={() => console.log('Decline')}
                    />
                )}
            {!!sessionName.length && isInStream && (
                <Box width="100%" height={fullHeight}>
                    <Box height={fullHeight}>
                        {isGroupCall ? (
                            <ZoomGroupCall
                                isForceDisconnect={isForceDisconnectEnable}
                                topic={sessionName}
                                token={operatorToken}

                            />
                        ) : (
                            <ZoomCall
                                isForceDisconnect={isForceDisconnectEnable}
                                topic={sessionName}
                                token={operatorToken}
                            />
                        )}
                    </Box>
                    {!streamAvailable && (
                        <Box style={styles.streamWrapper}>
                            <Lottie options={lottieOptions} />
                        </Box>
                    )}
                </Box>
            )}
            {!sessionName.length && !isInStream && <Logo />}
        </Box>
    )
}

const styles = {
    wrapper: {
        position: 'relative',
        verticalAlign: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        display: 'flex'
    },
    countdownWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        position: 'absolute',
        top: 16,
        left: 16,
        borderRadius: 12,
        border: '1px solid',
        borderColor: 'orange',
        color: 'orange',
        backgroundColor: '#fff',
        padding: 8,
        zIndex: 200
    },
    streamWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        top: 'calc(-100vh + 40px)'
    }
}
