import React, { useState, useEffect, useContext } from 'react'
import { Box, Grid, List, ListItem, ListItemText } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { Person } from '@material-ui/icons'

import ZoomContext from '../../context/zoom-context'
import ChatIcon from '../../assets/chatIcon.svg'
import ChatIconInactive from '../../assets/ChatInactive.svg'
import { Chat } from './Chat'
import { useCallContext } from 'src/reducers/Call/CallProvider'
import { ZoomActions } from 'src/types'
import { UsersToolbar } from './UsersToolbar'

export const ZoomOperatorsPanel = () => {
    const zmClient = useContext(ZoomContext)
    const {
        callState: { selectedCall },
        usersInSession,
        setUsersInSession
    } = useCallContext()
    const [messages, setMessages] = useState([])
    const [value, setValue] = useState(false)
    const isGroupCall = selectedCall && selectedCall.reason === 'group'
    const streamRef = React.useRef(zmClient.getMediaStream())
    const commandChannelRef = React.useRef(zmClient.getCommandClient())
    // zmClient.removeUser
    // console.log('selectedCall', selectedCall)
    // console.log('isGroupCall', isGroupCall)
    useEffect(() => {
        const messageReceiver = (payload) => {
            setMessages((prev) => [
                { from: payload.sender.name, data: payload.message },
                ...prev
            ])
            setValue(0)
            // console.log(payload)
        }

        zmClient.on('chat-on-message', messageReceiver)

        return () => {
            zmClient.off('chat-on-message', messageReceiver)
        }
    }, [zmClient])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const closeTab = () => {
        setValue(false)
    }

    const handleAttachVideo = (userId) => {
        const attachCommand = ZoomActions.ATTACH_USER
        // console.log('commandChannelRef.current', commandChannelRef.current)
        commandChannelRef.current
            .send(JSON.stringify({ action: attachCommand, target: userId }))
            .then(() => {
                setUsersInSession((prev) => {
                    return prev.map((el) =>
                        el.userId === userId
                            ? { ...el, isActiveUser: true }
                            : el
                    )
                })
                return streamRef.current.attachVideo(userId, 3)
            })
            .then((userVideo) => {
                const videoPlayerContainer = document.querySelector(
                    'video-player-container'
                )
                if (videoPlayerContainer) {
                    videoPlayerContainer.appendChild(userVideo)
                } else {
                    console.error('Video player container not found')
                }
            })
            .catch((error) => {
                console.error('Error attaching video:', error)
            })
    }

    const handleDetachVideo = (userId) => {
        const detachCommand = ZoomActions.DETACH_USER
        commandChannelRef.current
            .send(JSON.stringify({ action: detachCommand, target: userId }))
            .then(() => {
                setUsersInSession((prev) => {
                    return prev.map((el) =>
                        el.userId === userId
                            ? { ...el, isActiveUser: false }
                            : el
                    )
                })
                const videoPlayerByNodeId = document
                    .querySelector('video-player-container')
                    .querySelector(`[node-id="${userId}"]`)
                if (videoPlayerByNodeId) {
                    videoPlayerByNodeId.remove()
                }
            })
            .catch((error) => {
                console.error('Error detaching video:', error)
            })
    }

    const handleRemoveUser = (userId) => {
        zmClient.removeUser(Number(userId))
    }

    const handleToggleAudio = (userId) => {
        const isMuted = zmClient.getMediaStream().isAudioMuted(userId)
        if (isMuted) {
            zmClient.getMediaStream().unmuteAudio(userId)
        } else {
            zmClient.getMediaStream().muteAudio(userId)
        }
    }

    return (
        <>
            <Box
                style={{
                    margin: 0,
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >
                <Box
                    flexDirection="row-reverse"
                    display="flex"
                    style={{ backgroundColor: 'transparent' }}
                >
                    <Box display="flex" style={{ width: 50 }}>
                        <Tabs
                            selectionFollowsFocus={true}
                            orientation="vertical"
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            <Tab
                                disabled={value === 0}
                                icon={
                                    value === 0 ? (
                                        <img src={ChatIcon} alt="" />
                                    ) : (
                                        <img src={ChatIconInactive} alt="" />
                                    )
                                }
                                style={{
                                    minWidth: 15,
                                    fontSize: 8
                                }}
                            />
                            {isGroupCall && (
                                <Tab
                                    disabled={value === 1}
                                    icon={
                                        <Person
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                color: 'red'
                                            }}
                                        />
                                    }
                                    label={
                                        <div>{usersInSession.length || 0}</div>
                                    }
                                    style={{
                                        minWidth: 15,
                                        fontSize: 8
                                    }}
                                ></Tab>
                            )}
                        </Tabs>
                    </Box>
                    {/* ---------------------------------------------- */}
                    <Box
                        display="inline-block"
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <TabPanel
                            value={value}
                            index={0}
                            style={{ backgroundColor: 'rgba(255,255,255,0)' }}
                        >
                            <Chat
                                closeTab={closeTab}
                                messages={messages}
                                setMessages={setMessages}
                            />
                        </TabPanel>
                        {isGroupCall && (
                            <TabPanel
                                value={value}
                                index={1}
                                style={{
                                    backgroundColor: 'rgba(255,255,255,0)'
                                }}
                            >
                                <UsersToolbar
                                    onAttach={(userId) => {
                                        usersInSession.forEach((el) => {
                                            handleDetachVideo(el.userId)
                                        })
                                        // usersInSession.forEach(el => el.isActiveUser = false)
                                        // set all users as inactive
                                        // set current user as active
                                        // attach video
                                        handleAttachVideo(userId)
                                    }}
                                    onDetach={handleDetachVideo}
                                    usersInSession={usersInSession}
                                    onCloseTab={closeTab}
                                    removeUser={handleRemoveUser}
                                    toggleAudio={handleToggleAudio}
                                />
                            </TabPanel>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
}

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <Paper
            square
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{
                height: 'calc(10vh - 70ps -55px)'
            }}
            {...other}
        >
            <Grid container direction="column">
                {value === index && children}
            </Grid>
        </Paper>
    )
}
