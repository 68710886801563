import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    icon: string
    text: string
}

export function ReasonChip({ icon, text }: Props) {
    const { t } = useTranslation()

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 70,
                minHeight: 20,
                border: '1px solid #008DFF',
                borderRadius: 57
                // marginBottom: 10
            }}
        >
            <img src={icon} alt={t(text) || ''} style={{ marginRight: 5 }} />
            {text ? (
                <p
                    style={{
                        fontSize: 10,
                        color: '#008DFF',
                        wordBreak: 'keep-all',
                        wordWrap:'break-word'
                    }}
                >
                    {t(text)}
                </p>
            ) : null}
        </div>
    )
}
