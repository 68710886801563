import { useEffect, useRef, useState, useContext } from 'react'

//import ZoomContext from '../context/zoom-context'
import ZoomVideo from '@zoom/videosdk'
import { Box } from '@material-ui/core'
import { ButtonBase } from '@material-ui/core'

import CamOnIcon from '../assets/camOnIcon.svg'
import CamOffIcon from '../assets/camOffIcon.svg'
import MicOnIcon from '../assets/micOnIcon.svg'
import MicOffIcon from '../assets/micOffIcon.svg'
import NoUser from '../assets/no-user-icon.svg'

import { useCallContext } from '../reducers/Call/CallProvider'

let videoDevices

export const ZoomCameraPreview = () => {
    //const [isCameraOff, setIsCameraOff] = useState(false)
    const { isOperatorMuted, setIsOperatorMuted, isCameraOff, setIsCameraOff } =
        useCallContext()

    const localVideoTrack = useRef(null)
    // const videoDevices = useRef([])

    //const zmClient = useContext(ZoomContext)

    useEffect(() => {
        ZoomVideo.getDevices().then((devices) => {
            videoDevices = devices.filter((device) => {
                return device.kind === 'videoinput'
            })

            localVideoTrack.current = ZoomVideo.createLocalVideoTrack(
                videoDevices[0].deviceId
            )
            localVideoTrack.current.start(
                document.querySelector('#operator-preview-video')
            )
        })

        return () => {
            console.log('destroy localVideoTrack ', localVideoTrack.current)
            localVideoTrack.current.stop()
        }
    }, [])

    // useEffect(() => {
    //     async function initializeVideo() {
    //         try {
    //             const devices = await ZoomVideo.getDevices()
    //             videoDevices.current = devices.filter(
    //                 (device) => device.kind === 'videoinput'
    //             )

    //             if (videoDevices.current.length > 0) {
    //                 localVideoTrack.current = ZoomVideo.createLocalVideoTrack(
    //                     videoDevices.current[0].deviceId
    //                 )
    //                 await localVideoTrack.current.start(
    //                     document.querySelector('#operator-preview-video')
    //                 )
    //             }
    //         } catch (error) {
    //             console.error('Error initializing video: ', error)
    //         }
    //     }

    //     initializeVideo()

    //     return () => {
    //         if (localVideoTrack.current) {
    //             localVideoTrack.current.stop()
    //         }
    //     }
    // }, []);

    const changeAudioStatus = () => {
        if (isOperatorMuted) {
            setIsOperatorMuted(false)
        } else {
            setIsOperatorMuted(true)
        }
    }

    const changeVideoStatus = () => {
        if (isCameraOff) {
            setIsCameraOff(false)
        } else {
            setIsCameraOff(true)
        }
        //      try {
        //   if (isCameraOff) {
        //     if (videoDevices.current.length > 0 && localVideoTrack.current) {
        //       await localVideoTrack.current.start(document.querySelector('#operator-preview-video'));
        //       setIsCameraOff(false);
        //     }
        //   } else {
        //     if (localVideoTrack.current) {
        //       await localVideoTrack.current.stop();
        //       setIsCameraOff(true);
        //     }
        //   }
        // } catch (error) {
        //   console.error('Error toggling video: ', error);
        // }
    }

    // const handleOperatorsCamera = () => {
    //     const stream = zmClient.getMediaStream()
    //     console.log('stream-----> ', zmClient.getSessionInfo().isInMeeting)

    //     if (isCameraOff) {
    //         if (zmClient.getSessionInfo().isInMeeting) {
    //             if (
    //                 (!stream.isSupportMultipleVideos() &&
    //                     typeof OffscreenCanvas === 'function') ||
    //                 /android/i.test(navigator.userAgent)
    //             ) {
    //                 // start video - video will render automatically on HTML Video Element if MediaStreamTrackProcessor is supported
    //                 stream
    //                     .startVideo({
    //                         videoElement:
    //                             document.querySelector('#self-view-video')
    //                     })
    //                     .then(() => {
    //                         // if MediaStreamTrackProcessor is not supported
    //                         if (
    //                             !(
    //                                 typeof MediaStreamTrackProcessor ===
    //                                 'function'
    //                             )
    //                         ) {
    //                             // render video on HTML Canvas Element
    //                             stream
    //                                 .renderVideo(
    //                                     document.querySelector(
    //                                         '#self-view-canvas'
    //                                     ),
    //                                     zmClient.getCurrentUserInfo().userId,
    //                                     254,
    //                                     143,
    //                                     0,
    //                                     0,
    //                                     3
    //                                 )
    //                                 .then(() => {
    //                                     // show HTML Canvas Element in DOM
    //                                     console.log(
    //                                         'Operators video rendered successfully in Canvas Element in DOM'
    //                                     )
    //                                 })
    //                                 .catch((error) => {
    //                                     console.log(
    //                                         'Error in render video ',
    //                                         error
    //                                     )
    //                                 })
    //                         } else {
    //                             // show HTML Video Element in DOM
    //                             console.log(
    //                                 'Operators video rendered successfully in Video Element in DOM'
    //                             )
    //                         }
    //                     })
    //                     .catch((error) => {
    //                         console.log('Error in render video ', error)
    //                     })
    //                 // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
    //             } else {
    //                 // start video
    //                 stream
    //                     .startVideo({ hd: true })
    //                     .then(() => {
    //                         // render video on HTML Canvas Element
    //                         stream
    //                             .renderVideo(
    //                                 document.querySelector('#self-view-canvas'),
    //                                 zmClient.getCurrentUserInfo().userId,
    //                                 254,
    //                                 143,
    //                                 0,
    //                                 0,
    //                                 3
    //                             )
    //                             .then(() => {
    //                                 // show HTML Canvas Element in DOM
    //                                 console.log(
    //                                     'Operators video rendered successfully in Canvas Element with SharedBufferArray'
    //                                 )
    //                             })
    //                             .catch((error) => {
    //                                 console.log('Error in render video ', error)
    //                             })
    //                     })
    //                     .catch((error) => {
    //                         console.log('Error in start video ', error)
    //                     })
    //             }
    //         }

    //         localVideoTrack.current
    //             .start(document.querySelector('#operator-preview-video'))
    //             .then(() => {
    //                 setIsCameraOff(false)
    //             })
    //             .catch((err) => {
    //                 console.log('Error in handleOperatorsCamera ', err)
    //             })
    //     } else {
    //         if (zmClient.getSessionInfo().isInMeeting) {
    //             stream.stopVideo()
    //         }
    //         localVideoTrack.current
    //             .stop()
    //             .then(() => {
    //                 setIsCameraOff(true)
    //             })
    //             .catch((err) => {
    //                 console.log('Error in handleOperatorsCamera ', err)
    //             })
    //     }
    // }

    return (
        <>
            <Box
                style={{
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    zIndex: 100,
                    marginLeft: 64,
                    width: 355,
                    height: 265
                }}
            >
                <video
                    style={{
                        height: '100%',
                        display: isCameraOff ? 'none' : 'block'
                    }}
                    id="operator-preview-video"
                ></video>
                {isCameraOff ? (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'lightgray'
                        }}
                    >
                        <img src={NoUser} alt="" />
                    </div>
                ) : null}
                <div
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        zIndex: 1000,
                        display: 'flex',
                        width: '25%',
                        justifyContent: 'center',
                        transform: 'translateX(-50%)'
                    }}
                >
                    <ButtonBase
                        //onClick={handleOperatorsCamera}
                        onClick={changeVideoStatus}
                        style={{
                            borderRadius: '50%',
                            marginRight: '10px'
                        }}
                    >
                        <img
                            style={{ width: 40, height: 40 }}
                            src={isCameraOff ? CamOffIcon : CamOnIcon}
                            alt=""
                        />
                    </ButtonBase>
                    <ButtonBase
                        onClick={changeAudioStatus}
                        style={{
                            borderRadius: '50%'
                        }}
                    >
                        <img
                            style={{ width: 40, height: 40 }}
                            src={isOperatorMuted ? MicOffIcon : MicOnIcon}
                            alt=""
                        />
                    </ButtonBase>
                </div>
            </Box>
        </>
    )
}
